import { GridWithGaps } from '@dreamstack/feature-components'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import tw, { css, styled, theme } from 'twin.macro'

// INFO: brookline styles not used now, adjust 'brookline' name if adding new tile in future
const GridTemplate = css`
  display: grid;
  grid-template:
    '. accentroBig accentroBig accentroBig accentroBig accentroBig accentroBig accentroBig accentroBig .'
    '. accentroBig accentroBig accentroBig accentroBig accentroBig accentroBig accentroBig accentroBig .'
    '. accentroBig accentroBig accentroBig accentroBig accentroBig accentroBig accentroBig accentroBig .'
    '. . accentroGmbh accentroGmbh accentroGmbh accentroGmbh propRate propRate propRate propRate'
    '. commit commit commit commit brookline brookline brookline brookline .';

  @media (min-width: ${theme`screens.md`}) {
    grid-template:
      '. accentroBig accentroBig accentroBig accentroBig . . . . . .'
      '. accentroBig accentroBig accentroBig accentroBig accentroGmbh accentroGmbh accentroGmbh . . .'
      '. accentroBig accentroBig accentroBig accentroBig accentroGmbh accentroGmbh accentroGmbh . . .'
      '. accentroBig accentroBig accentroBig accentroBig commit commit brookline brookline brookline .'
      '. . propRate  propRate propRate commit commit brookline brookline brookline .'
      '. .  propRate propRate propRate . . . . . .';
    @media (min-width: ${theme`screens.ml`}) {
      grid-template:
        '. accentroBig accentroBig accentroBig accentroBig . . . . . .'
        '. accentroBig accentroBig accentroBig accentroBig accentroGmbh accentroGmbh accentroGmbh . . .'
        '. accentroBig accentroBig accentroBig accentroBig accentroGmbh accentroGmbh accentroGmbh . . .'
        '. accentroBig accentroBig accentroBig accentroBig commit commit brookline brookline brookline .'
        '. . propRate  propRate propRate commit commit brookline brookline brookline .'
        '. .  propRate propRate propRate . . . . . .';
    }
  }
`

const companyTileBgColor = '#1f2027'

const StyledGridWithGaps = styled(GridWithGaps)(() => [
  GridTemplate,
  tw`grid gap-px-8`,
])

const ImageSection = styled.div<{
  accentroRealEstate?: boolean
}>(({ accentroRealEstate }) => [
  tw` aspect-w-3 aspect-h-2 relative w-full h-full self-center bg-accentroGray-full md:p-px-80 ml:py-px-128 `,
  css`
    img {
      padding: 25px !important;
    }
  `,
  accentroRealEstate &&
    tw`bg-accentroAqua-full md:(p-px-160) ml:(py-px-160 px-px-96 padding-top[300px])`,
  accentroRealEstate &&
    css`
      img:first-child {
        padding: 60px !important;
      }
    `,
])

export const CompanyStructure: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const accentroRealEstate = '/static/images/accentro-ag-real-estate.png'
  const accentroGmbH = '/static/images/accentro-gmbh.png'
  // const brookline = '/static/images/brookline.png'
  const commit = '/static/images/commit.png'
  const proprate = '/static/images/proprate.png'

  return (
    <StyledGridWithGaps>
      <ImageSection tw="grid-area[accentroBig]" accentroRealEstate>
        <Image
          objectFit="contain"
          alt="accentro real estate"
          src={accentroRealEstate}
          layout="fill"
        />
      </ImageSection>
      <ImageSection
        style={{ backgroundColor: companyTileBgColor }}
        tw="grid-area[propRate]"
      >
        <Image
          objectFit="contain"
          alt="proprate"
          src={proprate}
          layout="fill"
          className="structureImage"
        />
      </ImageSection>
      <ImageSection
        style={{ backgroundColor: companyTileBgColor }}
        tw="grid-area[accentroGmbh]"
      >
        <Image
          objectFit="contain"
          alt="accentro GmbH"
          src={accentroGmbH}
          layout="fill"
          className="structureImage"
        />
      </ImageSection>
      <ImageSection
        style={{ backgroundColor: companyTileBgColor }}
        tw="grid-area[commit]"
      >
        <Image
          objectFit="contain"
          alt="commit"
          src={commit}
          layout="fill"
          className="structureImage"
        />
      </ImageSection>
      {/* INFO: Brookline Tile removed, if we want to add a new Tile again in future, use this commented code and adjust it */}
      {/* <ImageSection
        style={{ backgroundColor: companyTileBgColor }}
        tw="grid-area[brookline]"
      >
        <Image
          objectFit="contain"
          alt="brookline"
          src={brookline}
          layout="fill"
          className="structureImage"
        />
      </ImageSection> */}
    </StyledGridWithGaps>
  )
}
